import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import './style.scss';

const DefaultHeader = ({ name }) => {

  const { t } = useTranslation();

  return (
      <div className="nominate-header">
        <Row justify="center">
          <Col span={18}>
            <h3>{t('UNCLAIMED_BUSINESS.NOMINATE.HEADER')}</h3>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={20}>
            <p><Trans>{t('UNCLAIMED_BUSINESS.NOMINATE.DESC', { name })}</Trans></p>
          </Col>
        </Row>
      </div>
    );
};

DefaultHeader.propTypes = {
  name: PropTypes.string
}

export default DefaultHeader;