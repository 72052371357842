import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Input, Form, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import DefaultHeader from './components/DefaultHeader';
import './style.scss';

const STATUS = {
  BEFORE: "BEFORE",
  DURING: "DURING",
  AFTER: "AFTER"
}

const UnclaimedBusiness = ({ business }) => {
  const { name, externalRefId: id } = business;
  const [status, setStatus] = useState(STATUS.BEFORE);
  const { t } = useTranslation();

  const submitEmail = (values) => {
    setStatus(STATUS.AFTER);
  }

  return (
    <div className="unclaimed-business">
      <div className="nominate">
        <div className="nominate-form">
          {status === STATUS.BEFORE && (
            <div>
              <DefaultHeader name={name} />
              <div className="before-submit">
                <Button disabled={true} className="btn-outlined-secondary business-button" /*type="primary" onClick={() => setStatus(STATUS.DURING)}*/>{t('UNCLAIMED_BUSINESS.NOMINATE.ACTION')}</Button>
              </div>
              {/*<Link className="owner" to="/onboard">{t('UNCLAIMED_BUSINESS.NOMINATE.OWNER')}</Link>*/}
            </div>
          )}

          {status === STATUS.DURING && (
            <div>
              <DefaultHeader name={name} />
              <div className="submit">
                <Form
                  layout="inline"
                  onFinish={submitEmail}
                >
                  <Form.Item>
                    <Input className="email-input" placeholder="Email"></Input>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {t('UNCLAIMED_BUSINESS.NOMINATE.ACTION')}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              {/*<Link className="owner" to="/onboard">{t('UNCLAIMED_BUSINESS.NOMINATE.OWNER')}</Link>*/}
            </div>
          )}

          {status === STATUS.AFTER && (
            <div>
              <Row justify="center">
                <Col span={18}>
                  <h3>{t('UNCLAIMED_BUSINESS.NOMINATE.SUCCESS.HEADER')}</h3>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={20}>
                  <p><Trans>{t('UNCLAIMED_BUSINESS.NOMINATE.SUCCESS.DESC', { name })}</Trans></p>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

UnclaimedBusiness.propTypes = {
  business: PropTypes.shape().isRequired,
};

export default UnclaimedBusiness;
