import React from 'react';

const Terms = () => {
  return (
    <div>
      <p><strong>CORONA CARECARD TERMS AND CONDITIONS FOR THE ONLINE SALE OF PRODUCTS</strong></p>
      <p><strong>1.</strong>          	<strong>THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS, AND EXCLUSIONS THAT APPLY TO YOU. PLEASE READ IT CAREFULLY. </strong></p>
      <p><strong>THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS. </strong></p>
      <p><strong>BY PLACING AN ORDER FOR PRODUCTS FROM THIS WEBSITE, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS AGREEMENT, AND YOU ACCEPT AND ARE BOUND BY THESE TERMS AND CONDITIONS. YOU AFFIRM THAT IF YOU PLACE AN ORDER ON BEHALF OF AN ORGANIZATION OR COMPANY, YOU HAVE THE LEGAL AUTHORITY TO BIND ANY SUCH ORGANIZATION OR COMPANY TO THESE TERMS AND CONDITIONS.</strong></p>
      <p><strong>YOU MAY NOT ORDER OR OBTAIN PRODUCTS OR SERVICES FROM THIS WEBSITE IF YOU (A) DO NOT AGREE TO THESE TERMS, (B) ARE NOT THE OLDER OF (i) AT LEAST 18 YEARS OF AGE OR (ii) LEGAL AGE TO FORM A BINDING CONTRACT WITH CORONA CARECARD, OR (C) ARE PROHIBITED FROM ACCESSING OR USING THIS WEBSITE OR ANY OF THIS WEBSITE'S CONTENTS, GOODS OR SERVICES BY APPLICABLE LAW.</strong></p>
      <p>These terms and conditions (these "<strong>Terms</strong>") apply to the purchase and sale of products and services through Corona Carecard Website (the "<strong>Site</strong>"). These Terms are subject to change by Corona Carecard (referred to as "<strong>us</strong>", "<strong>we</strong>", or "<strong>our</strong>" as the context may require) without prior written notice at any time, in our sole discretion. Any changes to the Terms will be in effect as of the "Last Updated Date" referenced on the Site. You should review these Terms prior to purchasing any product that is available through this Site. Your continued use of this Site after the "Last Updated Date" will constitute your acceptance of and agreement to such changes.</p>
      <p>These Terms are an integral part of the Website Terms of Use that apply generally to the use of our Site. You should also carefully review our Privacy Policy before placing an order for products or services through this Site (see Section 10).</p>
      <p>2.          	<u>Order Acceptance and Cancellation</u>. You agree that your order is an offer to buy, under these Terms, all products listed in your order. All orders must be accepted by us or we will not be obligated to sell the products to you. We may choose not to accept orders at our sole discretion, even after we send you a confirmation email with your order number and details of the items you have ordered.</p>
      <p>3.          	<u>Prices and Payment Terms</u>.</p>
      <p>(a)         	The price charged for a product will be the price in effect at the time the order is placed and will be set out in your order confirmation email.  Posted prices do not include processing fees, taxes or charges for shipping and handling. All such amounts will be added to your merchandise total, and will be itemized in your shopping cart and in your order confirmation email. We strive to display accurate price information, however we may, on occasion, make inadvertent typographical errors, inaccuracies or omissions related to pricing and availability. We reserve the right to correct any errors, inaccuracies, or omissions at any time and to cancel any orders arising from such occurrences.  All prices, discounts, and promotions posted on this Site are subject to change without notice. </p>
      <p>(b)        	Terms of payment are within our sole discretion and, unless otherwise agreed by us in writing, payment must be received by us before our acceptance of an order. We accept credit and debit cards and other forms of electronic payment for all purchases. You represent and warrant that (i) the credit or debit card information you supply to us is true, correct and complete, (ii) you are duly authorized to use such credit or debit card for the purchase, (iii) charges incurred by you will be honored by your credit or debit card company, and (iv) you will pay charges incurred by you at the posted prices, including additional charges and all applicable taxes, if any, regardless of the amount quoted on the Site at the time of your order.</p>
      <p>(c) 	All payments must be in United States dollars. Current billing address and phone information must be included with every order. </p>
      <p>4.          	<u>Returns and Refunds</u><u>. </u> All purchases on the Site are final, non-refundable and non-returnable.  </p>
      <p>5.          	<u>Redeeming Gift Cards</u>. </p>
      <ol><li>Products purchased through the Site may only be redeemed through their designated retailer. Products may not be redeemed towards purchases made previously. Every purchase made with a Gift Card (plus any applicable shipping/handling fees and taxes) is deducted from the Gift Card balance and any unused Gift Card balance will be forfeited. If any purchase exceeds the Gift Card account, the balance must be paid with a credit card or any other payment method accepted by the retailer.</li>
      <li>Balances on multiple Gift Cards cannot be combined into a combined balance on one or more new Gift Cards and Gift Cards cannot be reloaded or recharged, or redeemed together with a coupon or other Gift Cards for the same order. Gift Cards may not be transferred for value or resold. Except to the extent required by applicable law, Gift Cards cannot be redeemed for cash or checks and Gift Card balances cannot be transferred to credit card accounts or accounts of other means of payment. Gift Cards can only be redeemed for purchases in the currency that matches the personal currency preferences set for the respective account. Retailer reserves the right to reject the redemption of Gift Cards and request alternative forms of payment, terminate the respective Account, and take legal actions, if retailer reasonably believes that Gift Cards are abused or used or obtained fraudulently. </li>
      <li>Title and risk of loss for Gift Cards passes upon electronic transmission of the Gift Card by Corona Carecard to the purchaser of the Gift Card. Corona Carecard has no obligation to replace lost or stolen Gift Cards.</li></ol>
      <p>6. 	<u>Tips for Businesses.</u> The ordering of any Tips follow all applicable terms and conditions of other Corona Carecard products and services but cannot be redeemed for any Corona Carecard or third-party product or service. Customers are making a voluntary payment without expectation of any exchange.</p>
      <p>7.          	<u>Manufacturer's Warranty and Disclaimers</u>. We do not manufacture or control any of the products or services offered on our Site. The availability of products or services through our Site does not indicate an affiliation with or endorsement of any retailer, product, service or manufacturer. Accordingly, we do not provide any warranties with respect to the retailers, products or services offered on our Site. </p>
      <p><strong>ALL PRODUCTS AND SERVICES OFFERED ON THIS SITE ARE PROVIDED “AS IS” WITHOUT ANY WARRANTY WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY (A) WARRANTY OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; OR (C) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.</strong></p>
      <p><strong>SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU.</strong></p>
      <p><strong>YOU AFFIRM THAT WE SHALL NOT BE LIABLE, UNDER ANY CIRCUMSTANCES, FOR ANY BREACH OF WARRANTY CLAIMS OR FOR ANY DAMAGES ARISING OUT OF THE RETAILER’S OR MANUFACTURER'S FAILURE TO HONOR ITS WARRANTY OBLIGATIONS TO YOU.</strong></p>
      <p>8.          <strong>	</strong><u><strong>Limitation of Liability</strong></u><strong>. IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR ENHANCED DAMAGES, LOST PROFITS OR REVENUES OR DIMINUTION IN VALUE, ARISING OUT OF, OR RELATING TO, AND/OR IN CONNECTION WITH ANY BREACH OF THESE TERMS, REGARDLESS OF (A) WHETHER SUCH DAMAGES WERE FORESEEABLE, (B) WHETHER OR NOT WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND (C) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH THE CLAIM IS BASED.</strong></p>
      <p><strong>OUR SOLE AND ENTIRE MAXIMUM LIABILITY, FOR ANY REASON, AND YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY CAUSE WHATSOEVER, SHALL BE LIMITED TO THE ACTUAL AMOUNT PAID BY YOU TO US FOR THE PRODUCTS AND SERVICES YOU HAVE ORDERED THROUGH OUR SITE.  IN NO EVENT SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY FAILURE THE RETAILER TO HONOR ANY GIFT CARD YOU PURCHASE FROM US FOR ANY REASON WHATSOEVER INCLUDING WITHOUT LIMITATION THE INSOLVENCY OR FINANCIAL CONDITION OF THE RETAILER.</strong></p>
      <p>The limitation of liability set forth above shall only apply to the extent permitted by law.</p>
      <p>9.          	<u>Goods Not for Resale or Export</u>. You represent and warrant that you are buying products or services from the Site for your own personal or household use only, and not for resale or export. You further represent and warrant that all purchases are intended for final delivery to locations within the US.</p>
      <p>10.          	<u>Privacy</u>. Our <i>Privacy Policy</i>, www.ccarecard.com/privacy-policy, governs the processing of all personal data collected from you in connection with your purchase of products or services through the Site.</p>
      <p>11.       	<u>Force Majeure</u>. We will not be liable or responsible to you, nor be deemed to have defaulted or breached these Terms, for any failure or delay in our performance under these Terms when and to the extent such failure or delay is caused by or results from acts or circumstances beyond our reasonable control, including, without limitation, acts of God, flood, fire, earthquake, explosion, governmental actions, war, invasion or hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest, national emergency, revolution, insurrection, epidemic, lockouts, strikes or other labor disputes (whether or not relating to our workforce), or restraints or delays affecting carriers or inability or delay in obtaining supplies of adequate or suitable materials, materials or telecommunication breakdown or power outage.</p>
      <p>12.       	<u>Governing Law and Jurisdiction</u>. This Site is operated from the US. All matters arising out of or relating to these Terms are governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether of the State of California or any other jurisdiction) that would cause the application of the laws of any jurisdiction other than those of the State of California.</p>
      <p>13.       	<u>Dispute Resolution and Binding Arbitration</u>.</p>
      <p><strong>(a)</strong>        	<strong>YOU AND CORONA CARECARD ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT OR BEFORE A JURY, OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.</strong></p>
      <p><strong>ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT OR FUTURE, AND INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW, INTENTIONAL TORT, INJUNCTIVE AND EQUITABLE CLAIMS) BETWEEN YOU AND US ARISING FROM OR RELATING IN ANY WAY TO YOUR PURCHASE OF PRODUCTS OR SERVICES THROUGH THE SITE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.</strong></p>
      <p>(b)        	The arbitration will be administered by the American Arbitration Association ("<strong>AAA</strong>") in accordance with the Consumer Arbitration Rules (the "<strong>AAA Rules</strong>") then in effect, except as modified by this Section 13. (The AAA Rules are available at www.adr.org/arb_med or by calling the AAA at 1-800-778-7879.) The Federal Arbitration Act will govern the interpretation and enforcement of this section.  The arbitration shall take place in San Francisco, California.</p>
      <p>The arbitrator will have exclusive authority to resolve any dispute relating to arbitrability and/or enforceability of this arbitration provision, including any unconscionability challenge or any other challenge that the arbitration provision is void, voidable, or otherwise invalid, except as stated in the last sentence of paragraph (c) below relating to the enforceability of the class arbitration waiver. The arbitrator will be empowered to grant whatever relief would be available in court under law or in equity. Any award of the arbitrator(s) will be final and binding on each of the parties, and may be entered as a judgment in any court of competent jurisdiction.</p>
      <p>(c)        	 You agree to an arbitration on an individual basis. In any dispute, <strong>NEITHER YOU NOR CORONA CARECARD WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS IN COURT OR IN ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY GENERAL CAPACITY</strong>. The arbitral tribunal may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding. The arbitral tribunal has no power to consider the enforceability of this class arbitration waiver and any challenge to the class arbitration waiver may only be raised in a court of competent jurisdiction.</p>
      <p>Any cause of action brought by you against us must be instituted within one year after the cause of action arises or be deemed forever waived and barred.</p>
      <p>If any provision of this arbitration agreement is found unenforceable, the unenforceable provision will be severed and the remaining arbitration terms will be enforced.</p>
      <p>14.       	<u>Assignment</u>. You will not assign any of your rights or delegate any of your obligations under these Terms without our prior written consent. Any purported assignment or delegation in violation of this Section 14 is null and void. No assignment or delegation relieves you of any of your obligations under these Terms.</p>
      <p>15.       	<u>No Waivers</u>. The failure by us to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any right or provision will be effective only if in writing and signed by a duly authorized representative of Corona CareCard.</p>
      <p>16.       	<u>No Third-Party Beneficiaries</u>. These Terms do not and are not intended to confer any rights or remedies upon any person other than you.</p>
      <p>17.       	<u>Notices</u>.</p>
      <p>(a)         	<u>To You</u>. We may provide any notice to you under these Terms by: (i) sending a message to the email address you provide or (ii) by posting to the Site. Notices sent by email will be effective when we send the email and notices we provide by posting will be effective upon posting. It is your responsibility to keep your email address current.</p>
      <p>(b)        	<u>To Us</u>. To give us notice under these Terms, you must contact us as follows: (i) by personal delivery, overnight courier, or registered or certified mail to [Corona Carecard, 10685-B Hazelhurst Dr. # <i>27200, </i>Houston, TX 77043, USA]. Notices provided by personal delivery will be effective immediately. Notices provided by registered or certified mail will be effective three business days after they are sent.</p>
      <p>18.       	<u>Severability</u>. If any provision of these Terms is invalid, illegal, void or unenforceable, then that provision will be deemed severed from these Terms and will not affect the validity or enforceability of the remaining provisions of these Terms.</p>
      <p>19.       	<u>Entire Agreement</u>. Our order confirmation, these Terms, our Website Terms of Use and our Privacy Policy will be deemed the final and integrated agreement between you and us on the matters contained in these Terms.</p>
  </div>
  );
}

export default Terms;
